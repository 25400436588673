import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import BrandCard from "../../components/BrandCard"
import Icon from "../../components/Icon"
import { BRANDS } from "../../constants"
import * as styles from "./brands.module.css"

export const Brands = ({ slice }) => {
  const { copy } = slice?.primary || {}

  return (
    <section data-color-scheme="scheme-2" id="brands">
      <div className={"page-width padding-section-vertical " + styles.inner}>
        {copy && (
          <PrismicRichText
            field={copy.richText}
            components={{
              paragraph: ({ children }) => (
                <p className="text-body-xl text-center">{children}</p>
              ),
            }}
          />
        )}
        <ul className={styles.grid}>
          {BRANDS.map(brand => (
            <li key={brand.name}>
              <BrandCard brand={brand} />
            </li>
          ))}
        </ul>
        <a href="mailto:hi@doingthings.com" className={styles.link}>
          Learn more about our other brands <Icon name="external" size=".9em" />
        </a>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyBrands on PrismicHomepageDataBodyBrands {
    primary {
      copy {
        richText
      }
    }
  }
`
